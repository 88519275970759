<template>
  <router-view />
</template>

<script>
export default {
  metaInfo: {
    title: "Dashboard"
  }
};
</script>
